<template>
  <iframe class="iframe" src="./loveyou/index.html" frameborder="0" style="width: 100%; height: 100%;;" />
</template>

<script>
export default {
  created() {
    console.log('love you create')
  }
}
</script>

<style scoped>
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }
</style>
